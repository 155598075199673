
.footer-style {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 10px;
    padding: 10px;


}

.footer-style-end {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 10px;
    padding: 10px;


}


.footer-style-mobile {
    position: relative;
    bottom: 0;
    right: 0;
    margin: 10px;
    padding: 10px;


}

.footer-style-end-mobile {
    position: relative;
    bottom: 0;
    left: 0;
    margin: 10px;
    padding: 10px;


}
.links{
    margin: 2px;
}

.text {
    right: 0;

}



