
.containers-center {
    margin-right: 20%;
    padding: 7%;
}

.img-about {
    box-shadow: 2rem 2rem 2rem #000;
    border-radius: 50px;
    width: 300px;
}

a {
    text-decoration: none;

}

p {
    font-size: 17px;
    font-weight: 550;
    text-align: start;

}

.qim-info {
    color: #61dafb;

}

span.wave {
    animation-name: wave-animation;
    animation-delay: 1s;
    animation-duration: 2.5s;
    animation-iteration-count: 1;
    transform-origin: 70% 70%;
    display: inline-block;
}

@keyframes wave-animation {
    0%,
    50% {
        transform: rotate(0deg);
    }
    10%,
    30% {
        transform: rotate(-10deg);
    }
    20% {
        transform: rotate(12deg);
    }
    40% {
        transform: rotate(9deg);
    }
}