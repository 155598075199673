.centering-contact {
    padding: 17%;
}

h3{
    margin-bottom: 4rem;
    text-shadow: 0.5rem 0.5rem 0.5rem black;
}
.Contact {position: relative;}

.email {
    color: rgb(141, 234, 255);
    text-decoration: none;
    font-style: inherit;

}

.linkedin {
    color: rgb(141, 234, 255);
    text-decoration: none;
    font-style: inherit;

}

