@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700');

.App {
    text-align: center;
    display: flex;
    flex-flow: column ;
}

@font-face {
    font-family: 'Varela Round';
    font-style: normal;
    font-weight: 400;
    src: local('Varela Round Regular'), local('VarelaRound-Regular'),
    url(https://fonts.gstatic.com/s/varelaround/v12/w8gdH283Tvk__Lua32TysjIfp8uPLdshZg.woff2)
    format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

#root {
    text-align: center;
    display: flex;
    flex-direction: column;
}

:root {
    --dark-gray: #2a2b2e;
    --dark-pink: #ff7c7c;
    --light-pink: #ffb6b9;
    --blue: #8deaff;
    --yellow: #ffe084;
    --light-gray: #d9d9d9;
    --white-almost: #f5f5f5;
}


.App-header {
    background-color: #2a2b2e !important;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white !important;
}

.App-link {
    color: #61dafb;
}


