.sep-text {
    display: table-column;
    horiz-align: center;
    background: coral;
    color: coral;
    padding-top: 5px;
    padding-bottom: 5px;

    font-size: 5px
}
.Project {
    position: relative ;
}
.line {
    width: 100%;
    height: 0;
    border: 1px solid coral;
    margin: 50px;
    display: inline-block;
}

.back-in-line {
    width: 100%;
    height: 0;
    border: transparent;
    margin-top: 30px;
}

.img-project {
    box-shadow: 2rem 2rem 2rem #000;
}

.code {
    width: 7%;
    text-decoration: none;
    color: coral;
    margin-top: 50px;
    border: 1px solid coral;
    border-radius: 9px;
}

.code:hover {
    box-shadow: 7px 4px 4px #000;
    color: coral;
}

.code-mobile {
    width: 13%;
    text-decoration: none;
    color: coral;
    margin-top: 70px;
    border: 1px solid coral;
    border-radius: 9px;

}

.code-mobile:hover {
    box-shadow: 7px 4px 4px #000;
    color: coral;
}
